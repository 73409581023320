import React, { useState,useRef } from 'react'
import banner from "../../Assets/Images/banner.png"
import "../../Assets/Styles/BannerContent.css"
import ModalPage from './ModalPage'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const BannerContent = () => {
    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_j08cs4l',
            'template_sxswmk4',
            form.current,
            'IzhxCt_ZfLQi2M9iK'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <div className='mb-4 mt-5 pt-md-5'>
            <div className='col col-12 col-md-12 bannerMain'>
                <div className='col col-md-12 col-12 '>
                    <div className='col col-12 col-md-12 titleBanner'>
                        <h3 className='titleBannerHeading'>Want to purse the Best Python Training with DLK Career Development?</h3>
                    </div>
                    <div className='d-flex justify-content-center mt-md-3' >
                        <div style={{ width: "50px", backgroundColor: "#e97b2d", height: "4px", boxShadow: '1px 2px 9px #e97b2d' }} />
                    </div>
                    <div className='col col-md-12 col-12 mt-md-5 d-flex flex-column flex-md-row bannerpara'>
                        <div className='col col-md-10 bannersubpara'>
                            <p>DLK Career Development is the best Python training and coaching centre for engineering
                                students, computer science students as well as beginners in Chennai. Our professional
                                experts and executives are well trained and experienced to handle special sessions
                                and training on Python for students. We assure that the students receive 100% placement
                                and certificate for course completion of the duration and fees are structured
                                and customised according to the comfort and convenience of the students in Chennai.
                            </p>
                        </div>
                    </div>
                    <div className='col col-md-12 col-12 bannerStarted'>
                        <button type="button" class="started" onClick={showModal}>Get Started</button>
                    </div>
                    <ModalPage
                        onFinishFailed={onFinishFailed}
                        onFinish={onFinish}
                        handleCancel={handleCancel}
                        showModal={showModal}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        sendEmail={sendEmail}
                        form={form}
                    />
                </div>
            </div>
        </div>
    )
}

export default BannerContent