import React from 'react'
import "../../Assets/Styles/Trusted.css"
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { AiFillStar } from "react-icons/ai"
import review5 from "../../Assets/Images/review5.jpg"
import review1 from "../../Assets/Images/review1.jpg"
import review2 from "../../Assets/Images/review2.jpg"
import review6 from "../../Assets/Images/review6.jpg"
import review3 from "../../Assets/Images/review2.jpg"
import review7 from "../../Assets/Images/review7.jpg"
const TrustedPeople = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 2
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 2
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 576, min: 0 },
            items: 1
        }
    };
    return (
        <div className='trusted'>
            <div className='col col-md-12 d-flex justify-content-center trainingheading'>
                <text className='trainingheading1'>Trusted by 25 Million Happy Learners</text>
            </div>
            <div className='d-flex justify-content-center mt-3' >
                <div style={{ width: "50px", backgroundColor: "#e97b2d", height: "4px", boxShadow: '1px 2px 9px #e97b2d' }} />
            </div>

            <div className='p-2 carouselMain'>
                <Carousel
                    autoPlay={true}
                    removeArrowOnDeviceType={["tablet", "mobile", "superLargeDesktop", "desktop"]}
                    responsive={responsive}
                    autoPlaySpeed={3000}
                    transitionDuration={500}
                    showDots={true}
                    dotListClass="custom-dot-list-style"
                >
                    <div className='container'>
                        <div className='container mainTestimonial px-4 d-flex justify-content-center'>
                            <img
                                className="itemContent"
                                src={review5}
                                alt="testimonial"
                            />
                        </div>
                    </div>
                    <div className='container px-md-2'>
                        <div className='mainTestimonial px-4 d-flex justify-content-center'>
                            <img src={review1} width={500} className='photosWithReview' />
                        </div>
                    </div>
                    <div className='container'>
                        <div className='container mainTestimonial px-4 d-flex justify-content-center'>
                            <img
                                className="itemContent"
                                src={review2}
                                alt="testimonial"
                            />
                        </div>
                    </div>
                    <div className='container px-md-2'>
                        <div className='mainTestimonial px-4 d-flex justify-content-center'>
                            <img src={review6} width={500} className='photosWithReview'></img>
                        </div>
                    </div>
                    <div className='container'>
                        <div className='container mainTestimonial px-4 d-flex justify-content-center'>
                            <img
                                className="itemContent"
                                src={review3}
                                alt="testimonial"
                            />
                        </div>
                    </div>
                    <div className='container px-md-2'>
                        <div className='mainTestimonial px-4 d-flex justify-content-center'>
                            <img src={review7} width={500} className='photosWithReview' />
                        </div>
                    </div>

                    {/* <div class="row justify-content-md-center">
                        <div class="col-12 col-md-6">
                            <div className='px-md-5'>
                                <img
                                    className="itemContent"
                                    src={review5}
                                    alt="testimonial"
                                />
                            </div>
                        </div>
                        <div class="col-md-5 col-12">
                            <div>
                                <div class="card-body2">
                                    <img src={review1} width={500} className='photosWithReview' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-md-6 col-12">
                            <div className='px-md-5'>
                                <img
                                    className="itemContent"
                                    src={review2}
                                    alt="testimonial"
                                />
                            </div>
                        </div>
                        <div class="col-md-5 col-12">
                            <div>
                                <div class="card-body2">
                                    <img src={review6} width={500} className='photosWithReview'></img>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-md-center">
                        <div class="col-md-6 col-12">
                            <div className='px-md-5'>
                                <img
                                    className="itemContent"
                                    src={review3}
                                    alt="testimonial"
                                />
                            </div>
                        </div>
                        <div class="col-md-5 col-12">
                            <div>
                                <div class="card-body2">
                                    <img src={review7} width={500} className='photosWithReview'></img>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </Carousel>
            </div>

        </div>
    )
}

export default TrustedPeople