import React from 'react'
import "../../Assets/Styles/Branches.scss"
import { SlLocationPin } from 'react-icons/sl';

const Branches = () => {
    return (
        <div className='branches'>
            <div className='d-flex flex-md-row flex-column branchMainCard'>
                <div class="container mt-4">
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/advance-java-training-in-chennai/'>
                            Advance Java Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/android-training-in-chennai/'>
                            Android Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/courses/'>
                            Arduino Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/asp-dot-net-training-in-chennai/'>
                            ASP.NET Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/cloud-computing-training-in-chennai/'>
                            Big Data Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/cloud-computing-training-in-chennai/'>
                            Cloud Sim Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/core-java-training-in-chennai/'>
                            Core Java Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/digital-marketing-training-in-chennai/'>
                            Digital Marketing Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/embedded-system-training-in-chennai/'>
                            Embedded Systems Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/hardware-and-networking-training-in-chennai/'>
                            Networking Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/hibernate-training-in-chennai/'>
                            Hibernate Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/matlab-training-in-chennai/'>
                            Matlab Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/mvc-training-in-chennai/'>
                            .NET MVC Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/oracle-training-in-chennai/'>
                            Oracle Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/php-training-in-chennai/'>
                            PHP Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/robotics-training-in-chennai/'>
                            Robotics Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/silver-light-training-in-chennai/'>
                            Silver Light Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/spring-training-in-chennai/'>
                            Java Spring Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>

                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/vlsi-training-in-chennai/'>
                            VLSI Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/wcf-training-in-chennai/'>
                            WCF Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/web-design-training-in-chennai/'>
                            Web Design Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/web-design-training-in-chennai/'>
                            Web Development Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/software-testing-training-in-chennai/'>
                            Software Testing Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/selenium-training-in-chennai/'>
                            Selenium Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/mobile-testing-training-in-chennai/'>
                            Mobile Testing Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/loadrunner-training-in-chennai/'>
                            QTP Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/loadrunner-training-in-chennai/'>
                            Loadrunner Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/angularjs-training-in-chennai/'>
                            Angular JS Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/aws-training-in-chennai/'>
                            AWS Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/sql-training-in-chennai/'>
                            SQL Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/sql-server-training-in-chennai/'>
                            SQL SERVER Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/mysql-training-in-chennai/'>
                            MYSQL Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/c-programming-training-in-chennai/'>
                            C Programming Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                    <span className='card4 custom_links_course'>
                        <a className='branchTab' href='https://training-in-chennai.co.in/c-programming-training-in-chennai/'>
                            C++ Training in Chennai
                        </a>
                        <div class="dimmer"></div>
                        <div class="go-corner" href="#"></div>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Branches