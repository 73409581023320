import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import photo1 from "../../Assets/Images/photo1.jpg"
import photo2 from "../../Assets/Images/photo2.jpg"
import photo3 from "../../Assets/Images/photo3.jpg"
import photo4 from "../../Assets/Images/photo4.jpg"
import photo5 from "../../Assets/Images/photo5.jpg"
import photo6 from "../../Assets/Images/photo6.jpg"
import photo7 from "../../Assets/Images/photo7.jpg"
import photo8 from "../../Assets/Images/photo8.jpg"
import photo9 from "../../Assets/Images/photo9.jpg"
import photo10 from "../../Assets/Images/photo10.jpg"
import photo11 from "../../Assets/Images/photo11.jpg"
import photo12 from "../../Assets/Images/photo12.jpg"
import photo13 from "../../Assets/Images/photo13.jpg"
import photo14 from "../../Assets/Images/photo14.jpg"
import photo15 from "../../Assets/Images/photo15.jpg"
import photo17 from "../../Assets/Images/photo17.jpg"
import photo18 from "../../Assets/Images/photo18.jpg"
import photo19 from "../../Assets/Images/photo19.jpg"
import photo20 from "../../Assets/Images/photo20.jpg"
import photo21 from "../../Assets/Images/photo21.jpeg"
import photo23 from "../../Assets/Images/photo23.jpeg"
import photo25 from "../../Assets/Images/photo25.jpg"
import photo26 from "../../Assets/Images/photo26.jpg"
import photo27 from "../../Assets/Images/photo27.jpg"
import photo28 from "../../Assets/Images/photo28.jpg"
import photo29 from "../../Assets/Images/photo29.jpg"
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const Gallery = () => {
  return (
    <div>
      <div >
        <div className='col col-md-12 d-flex justify-content-center trainingheading'>
          <text className='programhighlightsTitile'>Clip Arts</text>
        </div>
        <div className='d-flex justify-content-center mt-3' >
          <div style={{ width: "50px", backgroundColor: "#e97b2d", height: "4px", boxShadow: '1px 2px 9px #e97b2d' }} />
        </div>
      </div>
      <div className='mt-5'>
        <OwlCarousel className="owl-theme mb-5" loop margin={20} nav>
          <div class="item">
            <img src={photo1}/>
          </div>
          <div class="item">
            <img src={photo2}/>
          </div>
          <div class="item">
            <img src={photo3}/>
          </div>
          <div class="item">
            <img src={photo4}/>
          </div>
          <div class="item">
            <img src={photo5}/>
          </div>
          <div class="item">
            <img src={photo6}/>
          </div>
          <div class="item">
            <img src={photo7}/>
          </div>
          <div class="item">
            <img src={photo8}/>
          </div>
          <div class="item">
            <img src={photo9}/>
          </div>
          <div class="item">
            <img src={photo10}/>
          </div>
          <div class="item">
            <img src={photo11}/>
          </div>
          <div class="item">
            <img src={photo12}/>
          </div>
          <div class="item">
            <img src={photo13}/>
          </div>
          <div class="item">
            <img src={photo14}/>
          </div>
          <div class="item">
            <img src={photo15}/>
          </div>         
          <div class="item">
            <img src={photo17}/>
          </div>
          <div class="item">
            <img src={photo18}/>
          </div>
          <div class="item">
            <img src={photo19}/>
          </div>
          <div class="item">
            <img src={photo20}/>
          </div>
          <div class="item">
            <img src={photo21}/>
          </div>          
          <div class="item">
            <img src={photo23}/>
          </div>          
          <div class="item">
            <img src={photo25}/>
          </div>
          <div class="item">
            <img src={photo26}/>
          </div>
          <div class="item">
            <img src={photo27}/>
          </div>
          <div class="item">
            <img src={photo28}/>
          </div>
          <div class="item">
            <img src={photo29}/>
          </div>
        </OwlCarousel>
      </div>
    </div>
  )
}

export default Gallery