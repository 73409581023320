import React from 'react'
import "../../Assets/Styles/Content.css"
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import ourlogo from "../../Assets/Images/ourlogo.png"
import { Link, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

const InsideNav = () => {
    return (
        <div className=' col col-md-12 '>
            <div className='besantNavbar '>
                <Navbar collapseOnSelect expand="lg" variant="dark">
                    <div className='col col-md-5 mt-4 px-2'>
                        <Navbar.Brand href="/"><h4 className='brandName'>
                            <img src={ourlogo} className=''></img>
                        </h4></Navbar.Brand>
                    </div>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse  id="responsive-navbar-nav ">
                        <div className='container col col-md-12 linking'>
                            <Nav className="me-auto">
                                <div className=' container mainlinking gap-3'>
                                    <Nav.Link onClick={() => window.open("https://training-in-chennai.co.in/courses/")}><h4 className='sublinking'>Courses</h4></Nav.Link>
                                    <Nav.Link onClick={() => window.open("https://training-in-chennai.co.in/corporatetraining/")}><h4 className='sublinking'>Corporate Training</h4></Nav.Link>
                                    <Link to="pgrm_highlight" smooth={true} duration={100}>
                                        <Nav.Link ><h4 className='sublinking'>Program Highlights</h4></Nav.Link>
                                    </Link>
                                    <Link to="course" smooth={true} duration={100}>
                                        <Nav.Link ><h4 className='sublinking'>Course Syllabus</h4></Nav.Link>
                                    </Link>
                                    <Link to="testimonial" smooth={true} duration={100}>
                                        <Nav.Link ><h4 className='sublinking'>Testimonials</h4></Nav.Link>
                                    </Link>
                                </div>
                            </Nav>
                        </div>
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </div>
    )
}

export default InsideNav