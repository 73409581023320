import React from 'react';
import { Collapse, Divider } from 'antd';
import "../../Assets/Styles/Accordion.css"

const { Panel } = Collapse;

const text = `The trainers are very professionals and friendly. Our trainers are passionate for training the students and well versed with concepts of modules and the students doubts will be also cleared in a good manner.`;
const text1 = `We offer world class teaching and top-notch training to help you discover and groom the technical ability in you. We provide best-in-class infrastructure, online resources, and exposure to you in this world of IT.`;
const text2 = `When we Certify a Professional , he knows exactly how to deliver a successful project. So get certified, showcase your skills and share your knowledge. Earning certification helps you as an IT professional establish credibility with both your employer and the greater IT industry.`;
const text3 = `Our fees is very normal and affordable to all the students in all their criteria.`;
const text4 = `Our Training classes offers flexible training options including Class room and online /off line training for your learning needs. Choose what to learn, and when and how to learn it. With our trainers or accredited instructors in a hands-on learning environment.`;
const text5 = `In placement, we support you in all the ways by giving you well training in your prescribed domain in which will you be able to handle by your own and well versed.`;

const AccordionItem = () => (
    <div className='accordionCard d-flex flex-column justify-content-center'>
        <div className='mb-4 col col-md-12 d-flex justify-content-center accordionWithCard'>
            <Collapse size="large collapseCard">
                <Panel style={{fontWeight:"bold",fontFamily:"Tahoma, sans-serif"}} header="Real-Time Experts as Trainers" key="1">
                    <p className='paraAccordion'>{text}</p>
                </Panel>
            </Collapse>
        </div>
        <div className='mb-3 col col-md-12 d-flex justify-content-center accordionWithCard'>
            <Collapse size="large collapseCard">
                <Panel style={{fontWeight:"bold"}} header="Live Project" key="2">
                    <p className='paraAccordion'>{text1}</p>
                </Panel>
            </Collapse>
        </div>
        <div className='mb-3 col col-md-12 d-flex justify-content-center accordionWithCard'>
            <Collapse size="large collapseCard">
                <Panel style={{fontWeight:"bold"}} header="Certification" key="3">
                    <p className='paraAccordion'>{text2}</p>
                </Panel>
            </Collapse>
        </div>
        <div className='mb-3 col col-md-12 d-flex justify-content-center accordionWithCard'>
            <Collapse size="large collapseCard">
                <Panel style={{fontWeight:"bold"}} header="Affordable Fees" key="4">
                    <p className='paraAccordion'>{text3}</p>
                </Panel>
            </Collapse>
        </div>
        <div className='mb-3 col col-md-12 d-flex justify-content-center accordionWithCard'>
            <Collapse size="large collapseCard">
                <Panel style={{fontWeight:"bold"}} header="Flexibility" key="5">
                    <p className='paraAccordion'>{text4}</p>
                </Panel>
            </Collapse>
        </div>
        <div className='mb-5 col col-md-12 d-flex justify-content-center accordionWithCard'>
            <Collapse size="large collapseCard">
                <Panel style={{fontWeight:"bold"}} header="Placement Support" key="5">
                    <p className='paraAccordion'>{text5}</p>
                </Panel>
            </Collapse>
        </div>
    </div>
);

export default AccordionItem;