import React from 'react'
import AccordionItem from './AccordionItem'
import "../../Assets/Styles/Trainer.scss"
import Certificate from './Certificate'

const Frequently = () => {
    return (
        <div className=' frequently'>
            <div className='col col-md-12 d-flex justify-content-center trainingheading'>
                <text className='trainingheading1'>Trainer Profile</text>
            </div>
            <div className='d-flex justify-content-center mt-3' >
                <div style={{ width: "50px", backgroundColor: "#e97b2d", height: "4px", boxShadow: '1px 2px 9px #e97b2d' }} />
            </div>
            <div className='col col-12 d-flex mt-5'>
                <div className='container flowerBullets'>
                    <ul class="burst">
                        <li>Our trainers are well versed in technical wise and they are knowledgeable in their professions.</li>
                        <li>Our trainers will explain the students in a very easy method, such a way the students will understand easily.</li>
                        <li>Our trainers will put their maximum effort in explaining the students</li>
                        <li>Our trainers have well experienced and put their full effort in explaining during the discussion with the students</li>
                        <li>Our trainers will give attention individually to all the student by one on one, until the student get well versed in it.</li>
                        <li>Our trainers have well experienced and put their full effort in explaining during the discussion with the students</li>
                    </ul>
                </div>
            </div>
            <div id="light" className='mt-5 lines'>
                <div id="lineh1"></div>
                <div id="lineh2"></div>
                <div id="lineh4"></div>
                <div id="lineh5"></div>
            </div>
            <div className='box fadeInUp accordionMain' style={{animationDelay:"2s"}}>
                <AccordionItem />
            </div>
            <div>
                <Certificate/>
            </div>
            
        </div>
    )
}
export default Frequently
