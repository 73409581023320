import React from 'react'
import "../../Assets/Styles/Cards.scss"
import classTrain from "../../Assets/Images/classTrain.png"
import desktop from "../../Assets/Images/desktop.png"
import one from "../../Assets/Images/one.png"
import one2one from "../../Assets/Images/one2one.png"
const Cards = () => {
    return (
        <div className='col col-md-12 d-flex flex-md-row flex-column justify-content-center mainCardWithanimation'>
            <div class="trainingCardMain p-md-0 p-3 mb-5">
                <div class="card col col-md-3 trainingCard">
                    <article>
                        <h2>Online  <br /> Training</h2>
                        <div class="title"></div>
                        <div class="pic"><img src={desktop} className='pictureImg' /></div>
                        <div class="desc">
                            Instructor
                            <br />
                            LED Live
                            <br />
                            Online Training
                        </div>
                    </article>
                </div>
            </div>
            <div class="trainingCardMain p-md-0 p-3 mb-5">
                <div class="card col col-md-3 trainingCard">
                    <article>
                        <h2>Classroom Training</h2>
                        <div class="title"></div>
                        <div class="pic"><img src={classTrain} className='pictureImg' /></div>
                        <div class="desc">
                            Instructor
                            <br />
                            LED Classroom
                            <br />
                            Training
                        </div>
                    </article>
                </div>
            </div>
            <div class="trainingCardMain p-md-0 p-3 mb-5">
                <div class="card col col-md-3 trainingCard">
                    <article>
                        <h2>One to One Training</h2>
                        <div class="title"></div>
                        <div class="pic"><img src={one2one} className='pictureImg' /></div>
                        <div class="desc">
                            Customized and Exclusive
                            <br />
                            training based on
                            <br />
                            your requirement.
                        </div>
                    </article>
                </div>
            </div>
            <div class=" trainingCardMain p-md-0 p-3 mb-5">
                <div class="card col col-md-3 trainingCard">
                    <article>
                        <h2>Team/Corporate Training</h2>
                        <div class="title"></div>
                        <div class="pic"><img src={one} className='pictureImg' /></div>
                        <div class="desc">
                            Customized Corporate
                            <br />
                            Training
                        </div>
                    </article>
                </div>
            </div>
        </div>
    )
}

export default Cards