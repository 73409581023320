import React from 'react'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ReactPlayer from "react-player";
import "../../Assets/Styles/HappyStudent.css"

const HappyStudent = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 550, min: 0 },
            items: 1
        }
    };
    return (
        <div className='mb-4'>
            <div className='col col-md-12 d-flex justify-content-center trainingheading'>
                <text className='trainingheading1'>Students Testimonials</text>
            </div>
            <div className='d-flex justify-content-center mt-3' >
                <div style={{ width: "50px", backgroundColor: "#e97b2d", height: "4px", boxShadow: '1px 2px 9px #e97b2d' }} />
            </div>

            <div>
                <div className='mainCarousel'>
                    <Carousel
                        autoPlay={true}
                        responsive={responsive}
                        autoPlaySpeed={3000}
                        transitionDuration={500}
                    >
                        <div className='px-4'>
                            <ReactPlayer
                                width="100%"
                                height="30vh"
                                url="https://www.youtube.com/watch?v=aaYVgyAqJQE"
                            />
                        </div>
                        <div className='px-4'>
                            <ReactPlayer
                                width="100%"
                                height="30vh"
                                url="https://www.youtube.com/watch?v=zMqCsUN1mGE"
                            />
                        </div>
                        <div className='px-4'>
                            <ReactPlayer
                                width="100%"
                                height="30vh"
                                url="https://www.youtube.com/watch?v=ejLnO6QTRso"
                            />
                        </div>
                        <div className='px-4'>
                            <ReactPlayer
                                width="100%"
                                height="30vh"
                                url="https://www.youtube.com/watch?v=pXeZdrjUfa8"
                            />
                        </div>
                        <div className='px-4'>
                            <ReactPlayer
                                width="100%"
                                height="30vh"
                                url="https://www.youtube.com/watch?v=6jTJHJNMNrQ"
                            />
                        </div>
                        {/* <div className='px-4'>
                            <ReactPlayer
                                width="100%"
                                height="30vh"
                                url="https://www.youtube.com/watch?v=jCO6n29f7yw"
                            />
                        </div> */}
                    </Carousel>
                </div>
                <div className='col col-md-12 col-12 watchMore'>
                    <button type="button" class="started"
                        onClick={() => window.open("https://www.youtube.com/@dlkcareerdevelopment3837")}
                    >Watch More videos</button>
                </div>
            </div>
        </div>
    )
}

export default HappyStudent