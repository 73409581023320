import React, { useState, useRef } from 'react'
import banner from "../../Assets/Images/certificateBg.jpg"
import certificate from "../../Assets/Images/certificate.jpg"
import "../../Assets/Styles/Certificate.css"
import ModalPage from './ModalPage'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const Certificate = () => {
  const form = useRef();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onFinish = (values) => {
    console.log('Success:', values);
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const sendEmail = (e) => {
    e.preventDefault();
    console.log("Sending Mail....");
    emailjs.sendForm(
      'service_j08cs4l',
      'template_sxswmk4',
      form.current,
      'IzhxCt_ZfLQi2M9iK'
    )
      .then((result) => {
        console.log(result.text);
        toast.success("Message Sent Successfully! We will get back to you soon.");
        e.target.reset();
        setIsModalOpen(false);
        console.log("message sent");
      }, (error) => {
        toast.error("Message Sending Failed! Please try again.")
        console.log(error.text);
      });
  };
  return (
    <div className='mb-4 mt-5 '>
      <section>
        <div className='col col-12 col-md-12 bannerMain'>
          <div className='bannerCourse d-md-flex d-none'>
            <img src={banner} className="bannerCertificateimg" />
          </div>
          <div className='bannerContent col col-md-12 col-12 '>
            <div className='col col-md-12 col-12  d-flex flex-md-row flex-column'>
              <div className='col col-md-6 col-12 mt-md-5'>
                <div className='col-12 d-flex justify-content-center' >
                  <h2 className='courseCerticatetitle'>Python Certification Training<br /> in <br /> Chennai</h2>
                </div>
                <div className='col col-md-12 col-12 bannerStarted'>
                  <button type="button" class="started" onClick={showModal}>Enquiry Now</button>
                </div>
              </div>
              <div className='container col col-md-6 col-12 '>
                <div className='d-flex align-items-center '>
                  <img src={certificate} className="certificateImg mt-5" />
                </div>
              </div>
            </div>
            <ModalPage
              onFinishFailed={onFinishFailed}
              onFinish={onFinish}
              handleCancel={handleCancel}
              showModal={showModal}
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              sendEmail={sendEmail}
              form={form}
            />
          </div>
        </div>
      </section>
      <section>
        <div className='py-md-5 mt-5'>
          <div className='courseTraining'>
            <div className='container courseMaterial mt-3 px-md-5'>
              <h2 className='materialTitle'>Course Training Meterial</h2>
              <div className='px-md-2'>
                <ul class="pac-man">
                  <li>We take one to one responsibility to every student</li>
                  <li>Study Materials will be given</li>
                  <li>Programming code - issued</li>
                  <li>Hardware - We Guidelines in Technology - will be explained well.make hardware, and show how is it working.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className=''>
            <div className='container courseMaterial mt-3 px-md-5'>
              <h2 className='materialTitle'>Course Certificate</h2>
              <div className='px-md-2'>
                <ul class="pac-man">
                  <li>After completion of the training, successfully, the student will be issued a course completion certificate.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className=''>
            <div className='container courseMaterial mt-3 px-md-5'>
              <h2 className='materialTitle'>Who Can Attend this Programme?</h2>
              <div className='px-md-2'>
                <ul class="pac-man">
                  <li>College students from 1st year to 4th year.</li>
                  <li>Fresh pass-out students.</li>
                  <li>Peoples who are searching good jobs.</li>
                </ul>
              </div>
            </div>
          </div>
          <div className=''>
            <div className='container courseMaterial mt-3 px-md-5'>
              <h2 className='materialTitle'>Outcome Of This Training Programme?</h2>
              <div className='px-md-2'>
                <ul class="pac-man">
                  <li>Will be a well-trained Programmer before graduation</li>
                  <li>Acceptance of work in all their fields</li>
                  <li>To develop good attitude, speedy in their work</li>
                  <li>Will get good placements in MNC's</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

    </div>
  )
}

export default Certificate