import React from 'react'
import "../../Assets/Styles/PgrmHighlight.scss"
import learning from "../../Assets/Images/learning.png"
import smallrect from "../../Assets/Images/smallrect.png"
import middlecircle from "../../Assets/Images/middlecircle.png"
const PgrmHighlight = () => {
    return (
        <div className='programHighlights'>
            <div >
                <div className='col col-md-12 d-flex justify-content-center trainingheading'>
                    <text className='programhighlightsTitile'>Program Highlights</text>
                </div>
                <div className='d-flex justify-content-center mt-3' >
                    <div style={{ width: "50px", backgroundColor: "#e97b2d", height: "4px", boxShadow: '1px 2px 9px #e97b2d' }} />
                </div>
            </div>

            <div>
                <div className='d-flex flex-md-row flex-column col col-md-12 '>
                    <div class="container col col-md-4 col-12 d-flex justify-content-center">
                        <div class=" mycard">
                            <div class="face face1">
                                <div class="content">
                                    <h3 className='mainheading1 '>COURSE TIMING & TRAINING HOURS</h3>
                                </div>
                            </div>
                            <div class="face face2">
                                <div class="content">
                                    <p className='para '>All days Monday to Saturday from 10 am to 7 pm. Training hours are 48 to 60 hours</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-md-4 col-12 container d-flex justify-content-center">
                        <div class=" mycard">
                            <div class="myface myface1">
                                <div class="content">
                                    <h3 className='mainheading1'>COURSE </h3>
                                </div>
                            </div>
                            <div class="myface myface2">
                                <div class="content">
                                    <p className='para'>Training in Software installation.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col col-md-4 col-12 container d-flex justify-content-center">
                        <div class=" mycard">
                            <div class="my3face my3face1">
                                <div class="content">
                                    <h3 className='mainheading1'>HARDWARE </h3>
                                </div>
                            </div>
                            <div class="my3face my3face2">
                                <div class="content">
                                    <p className='para'>We make hardware, and show how is it working.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-md-row flex-column col col-md-12 gap-md-5 '>
                    <div class="container holecardWithimg1">
                        <div class=" mycard">
                            <div class="my4face my4face1">
                                <div class="content">
                                    <h3 className='mainheading1'>PROJECT REQUIREMENTS</h3>
                                </div>
                            </div>
                            <div class="my4face my4face2">
                                <div class="content">
                                    <p className='para mt-3'>Course Documentation, with PPT or PDF, and project report.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="container holecardWithimg2 mb-md-0 mb-5 pb-md-0 pb-4.rightcard">
                        <div class=" mycard ">
                            <div class="my5face my5face1">
                                <div class="content">
                                    <h3 className='mainheading1'>CERTIFICATE </h3>
                                </div>
                            </div>
                            <div class="my5face my5face2">
                                <div class="content">
                                    <p className='para'>After Successful completion of the training, certification will be done by us.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='col col-12 d-md-flex justify-content-start d-none'>
                <img src={smallrect} className='smallrect'></img>
            </div>
            <div className='col col-12 d-md-flex justify-content-start d-none px-5'>
                <img src={middlecircle} className='middlecircle'></img>
            </div>
        </div>
    )
}

export default PgrmHighlight