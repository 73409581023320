import React, { useState,useRef } from "react";
import "../../Assets/Styles/Python.scss"
import ModalPage from "./ModalPage";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const Python = ({ }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_j08cs4l',
            'template_sxswmk4',
            form.current,
            'IzhxCt_ZfLQi2M9iK'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <div className="container">
            <h2>
                <div>
                    <h4 className='pythontitle'>About Python Training</h4>
                    <p className='paraPython'>Python is a general-purpose interpreted, interactive, object-oriented,
                        and high-level programming language. It was created by Guido van Rossum during 1985- 1990.
                        Like Perl, Python source code is also available under the GNU General Public License (GPL).
                        This tutorial gives enough understanding of Python programming language.
                    </p>
                </div>
                <div>
                    <h4 className='pythontitle'>Python Courses at DLK</h4>
                    <div className="col col-md-12 d-flex flex-md-row flex-column">
                        <div className="col col-md-12">
                            <text>
                                <p className='paraPython'>
                                    Python Training Institute in Chennai. Python is Interpreted: Python is processed at runtime by the interpreter. You do not need to compile your program before executing it. This is similar to PERL and PHP.
                                </p>
                                <p className='paraPython1'>
                                    Python is Interactive: You can actually sit at a Python prompt and interact with the interpreter directly to write your programs.
                                </p>
                                <p className='paraPython1'>
                                    Python is Object-Oriented: Python supports Object-Oriented style or technique of programming that encapsulates code within objects.
                                </p>
                                <p className='paraPython1'>
                                    <strong>Python is a Beginner’s Language:</strong> Python is a great language for beginner-level programmers and supports the development of a wide range of applications from simple text processing to WWW browsers to games
                                </p>
                                <p className='paraPython1'>
                                    A high-level and functional programming language that designs code for software and
                                    android applications. Python is dynamic to understand and easy to optimise.
                                    This enriches the business and webpages to run in a systematic and productive way.
                                    It is object-oriented and reflective to adapt in nature.
                                    The syntax is similar to the English Language. The codes are executed as written by
                                    the software developer. DLK Career Development is the best Python training and
                                    coaching centre for engineering students, computer science students as well as
                                    beginners in Chennai. Our professional experts and executives are well trained and
                                    experienced to handle special sessions and training on Python for students. We assure
                                    that the students receive 100% placement and certificate for course completion of
                                    the duration and fees are structured and customised according to the comfort and
                                    convenience of the student in Chennai.
                                </p>
                            </text>
                        </div>
                    </div>
                    <div className='col col-md-12 col-12 watchMore'>
                        <button type="button" class="started"
                            onClick={showModal}
                        >Contact Us</button>
                    </div>
                    <ModalPage
                        onFinishFailed={onFinishFailed}
                        onFinish={onFinish}
                        handleCancel={handleCancel}
                        showModal={showModal}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        sendEmail={sendEmail}
                                    form={form}
                    />
                </div>
            </h2>
        </div>
    );
};

export default Python;