import React, { useState, useRef } from 'react'
import "../../Assets/Styles/Content.css"
import { AiFillCheckCircle } from 'react-icons/ai';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from "react-google-recaptcha";

const Section1Content = () => {
    const form = useRef();
    const recaptchaRef = React.createRef();
    function onChange(value) {
        console.log("Captcha value:", value);
    }

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_j08cs4l',
            'template_sxswmk4',
            form.current,
            'IzhxCt_ZfLQi2M9iK'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <div className='col col-md-12 '>
            <div className='p-md-3 p-4 navbaroverimage d-flex flex-column flex-md-row'>
                <div className='col col-md-7'>
                    <h3 className='mainheading' >Python Training & Placement</h3>
                    <ToastContainer />
                    <div className="vl col col-12">
                        <h4 className='subheading1'><strong>DLK Career Development</strong> #1 Python Training Institute with Placement.
                        </h4>
                    </div>
                    <div className="submainheading">
                        <h6 className='subheading2'>DLK Career Development provides
                            the Best Python Training in Chennai to Learn & Get Expertise through Internships and Training in with 100% placements.
                            <br /> Learn Python Programming from Basics to Advanced and get real-time experience.</h6></div>
                    <div className="submainheading1">
                        <div className='d-flex flex-row'>
                            <h6 className='subheading3-1'>Enroll Now for Trending Courses with Job Assurance</h6>
                        </div>
                        <div className='d-flex flex-row mt-1'>
                            <AiFillCheckCircle className='tickicons' />
                            <h6 className='subheading3'>Real Time Experts as Trainers</h6>
                        </div>
                        <div className='d-flex flex-row'>
                            <AiFillCheckCircle className='tickicons' />
                            <h6 className='subheading3'>Certification</h6>
                        </div>
                        <div className='d-flex flex-row'>
                            <AiFillCheckCircle className='tickicons' />
                            <h6 className='subheading3'>Flexibility</h6>
                        </div>
                        <div className='d-flex flex-row'>
                            <AiFillCheckCircle className='tickicons' />
                            <h6 className='subheading3'>LIVE Project</h6>
                        </div>
                        <div className='d-flex flex-row'>
                            <AiFillCheckCircle className='tickicons' />
                            <h6 className='subheading3'>Affordable Fees</h6>
                        </div>
                        <div className='d-flex flex-row'>
                            <AiFillCheckCircle className='tickicons' />
                            <h6 className='subheading3'>Placement Support</h6>
                        </div>
                    </div>
                </div>
                <div className='col col-md-5 px-md-5 mt-md-0 mt-3'>

                    <div id="contact_wrap">
                        <h3>Contact Us</h3>
                        <form name="contact-form" ref={form} onSubmit={sendEmail}>
                            <input
                                id="ContactForm1_contact-form-name"
                                placeholder="Name"
                                size="30"
                                required
                                type="text"
                                name="user_name"
                            />
                            <input
                                id="ContactForm1_contact-form-email"
                                required
                                placeholder="Email"
                                size="30"
                                type="text"
                                name="user_email"
                            />
                            <input
                                required
                                id="ContactForm1_contact-form-phone"
                                name="user_phone"
                                placeholder="phone"
                                size="30"
                                type="number"

                            />
                            <textarea
                                required
                                cols="20"
                                id="ContactForm1_contact-form-email-message"
                                name="message"
                                placeholder="Message"
                                rows="2"
                            />
                            <ReCAPTCHA
                                ref={recaptchaRef}
                                sitekey="6LddddAkAAAAAHmM5ek3WBFUWY2mMI9wwEvNthFk"
                                onChange={onChange}
                            />
                            <button
                                id="ContactForm1_contact-form-submit"
                                type="submit"

                            >Submit</button>
                        </form>
                    </div>
                </div>
            </div>
            <ToastContainer />
        </div>
    )
}

export default Section1Content