import React from 'react'
import "../../Assets/Styles/Nav.css"

const Copyright = () => {
    return (
        <div className='col col-md-12'>
            <div className='navbarstyle d-flex justify-content-center align-items-center'>
                <div className='contact'>
                    © 2022. Design & Developed by <a style={{color:"black"}} onClick={() => window.open("https://dlktech.co.in/")}> DLK Technologies Pvt Ltd</a>. All Rights Reserved.
                </div>
            </div>
        </div>
    )
}

export default Copyright
