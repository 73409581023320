import React from 'react'
import "../../Assets/Styles/Guidance.scss"

const Guidance = () => {
    return (
        <div className='guidanceMain'>
            <div className='col col-md-12 d-md-flex justify-content-center trainingheading'>
                <text className='trainingheading2 '>You Always Get the Best Guidance</text>
            </div>
            <div className='d-flex justify-content-center mt-3' >
                <div style={{ width: "50px", backgroundColor: "#e97b2d", height: "4px", boxShadow: '1px 2px 9px #e97b2d' }} />
            </div>

            <div className='d-flex flex-md-row flex-column wholeCircle'>
                <div class="mt-4">
                    <div class="block d-flex flex-column ">
                        <div class="hovicon effect-4 sub-b d-flex flex-column">
                            <div className='numbers'>10+ </div>
                            <div className='circletitle'>Years</div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="block d-flex flex-column ">
                        <div class="hovicon effect-4 sub-b d-flex flex-column">
                            <div className='numbers'>120K </div>
                            <div className='circletitle'>Courses</div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="block d-flex flex-column ">
                        <div class="hovicon effect-4 sub-b d-flex flex-column">
                            <div className='numbers'>50000+ </div>
                            <div className='circletitle'>Alumni</div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="block d-flex flex-column ">
                        <div class="hovicon effect-4 sub-b d-flex flex-column">
                            <div className='numbers'>120+</div>
                            <div className='circletitle'>Hiring Partner</div>
                        </div>
                    </div>
                </div>
                <div class="mt-4">
                    <div class="block d-flex flex-column ">
                        <div class="hovicon effect-4 sub-b d-flex flex-column">
                            <div className='numbers'>100+</div>
                            <div className='circletitle'>Expert Trainers</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Guidance