import Nav from './Components/Mainsource/Navbar/Nav';
import Content from "../src/Components/Mainsource/Content/Content"
function App() {
  return (
    <div>
      <Nav />
      <Content />
    </div>
  );
}

export default App;
