import React, { useState, useRef } from 'react'
import { Button, Checkbox, Form, Input, Modal } from 'antd';
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';
const { TextArea } = Input;

const ModalPage = ({ form, sendEmail, setIsModalOpen, onFinishFailed, onFinish, handleCancel, showModal, isModalOpen }) => {
    const recaptchaRef = React.createRef();
    function onChange(value) {
        // console.log("Captcha value:", value);
        setIsModalOpen(true)
    }

    return (
        <div>
            <Modal open={isModalOpen} onCancel={handleCancel} footer={[]}>
                <h4 className=''>Enquiry Now</h4>
                <div className='px-4 pt-3'>
                    <form ref={form} onSubmit={sendEmail}>
                        <div>
                            <label for="exampleInputEmail1" class="form-label">
                                Name*
                            </label>
                            <input
                                required
                                type="text"
                                name="user_name"
                                class="form-control"
                                aria-describedby="emailHelp"
                                id="inputbox"
                            />
                        </div>
                        <div class="">
                            <label for="exampleInputPassword1" class="form-label">
                                Email*
                            </label>
                            <input
                                required
                                type="email"
                                class="form-control"
                                id="inputbox"
                                name="user_email"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="">
                            <label for="exampleInputPassword1" class="form-label">
                                Phone*
                            </label>
                            <input
                                required
                                type="number"
                                class="form-control"
                                id="inputbox"
                                name="user_phone"
                                aria-describedby="emailHelp"
                            />
                        </div>
                        <div class="mb-3">
                            <label for="exampleInputPassword1" class="form-label">
                                Message*
                            </label>
                            <textarea
                                class="form-control"
                                rows="3"
                                id="textarea"
                                name="message"
                            ></textarea>
                        </div>
                        <ReCAPTCHA
                        ref={recaptchaRef}
                            sitekey="6LddddAkAAAAAHmM5ek3WBFUWY2mMI9wwEvNthFk"
                            onChange={onChange}
                        />
                        <div class="text-center mt-2 ps-4">
                            <button type="submit" style={{ border: "1px solid black", fontSize: "18px" }} class="btn btn-dark btn-sm me-2">
                                SEND
                            </button>
                        </div>
                    </form>
                </div>
            </Modal>
        </div>
    )
}

export default ModalPage