import React, { useState, useRef } from 'react'
import "../../Assets/Styles/Nav.css"
import ModalPage from '../Content/ModalPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const Nav = () => {
    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_j08cs4l',
            'template_sxswmk4',
            form.current,
            'IzhxCt_ZfLQi2M9iK'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <div className='col col-12 col-md-12' id='about'>
            <div className='navbarstyle d-flex justify-content-center align-items-center'>
                <div className='col-12 container contact d-flex justify-content-center'>
                    Get Started Learning Python with industrial experts Today, Enroll Now & Get Hired!!!
                </div>
            </div>
            <div className='col col-md-12 navbarstyle1 d-flex flex-md-row flex-column justify-content-center align-items-center'>
                <div className='col col-md-6 col-12  d-flex justify-content-md-start justify-content-center contactNumber'>
                    Chennai: +91 9751800789 | +91 7299951536
                </div>
                <div className='col col-md-6 col-12 d-flex flex-md-row flex-column justify-content-md-end align-items-center mb-4 mb-md-0'>
                    <div className='col col-md-6 px-3 d-flex justify-content-md-end'>
                        HIRE TALENT (HR): +91 7299951536
                    </div>
                    <div className='col col-md-6 d-flex justify-content-md-start justify-content-center mt-md-0 mt-2'>
                        <button type="button" class="download1" onClick={showModal}>Python Training - With 100% Placement</button>
                    </div>
                    <ModalPage
                        onFinishFailed={onFinishFailed}
                        onFinish={onFinish}
                        handleCancel={handleCancel}
                        showModal={showModal}
                        isModalOpen={isModalOpen}
                        setIsModalOpen={setIsModalOpen}
                        sendEmail={sendEmail}
                        form={form}
                    />
                </div>
            </div>
        </div>
    )
}

export default Nav
