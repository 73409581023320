import React from 'react'
import "../../Assets/Styles/Training.css"
import Cards from './Cards'

const Training = () => {
    return (
        <div className='trainingwholeMain '>
            <div>
                <div className='col col-md-12 d-flex justify-content-center'>
                    <text className='trainingMainheading1 mt-5'>We Offer</text>
                </div>
                <div className='d-flex justify-content-center mt-3' >
                    <div style={{ width: "50px", backgroundColor: "#e97b2d", height: "4px", boxShadow: '1px 2px 9px #e97b2d' }} />
                </div>
            </div>
            <div className='mainCardWithContent'>
                <Cards />
            </div>
        </div>
    )
}

export default Training


