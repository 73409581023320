import React, { useRef, useState } from 'react'
import "../../Assets/Styles/Footer.css"
import ModalPage from './ModalPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from '@emailjs/browser';

const FooterAnime = () => {
    const form = useRef();
    const [isModalOpen, setIsModalOpen] = useState(false);

    const onFinish = (values) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const sendEmail = (e) => {
        e.preventDefault();
        console.log("Sending Mail....");
        emailjs.sendForm(
            'service_j08cs4l',
            'template_sxswmk4',
            form.current,
            'IzhxCt_ZfLQi2M9iK'
        )
            .then((result) => {
                console.log(result.text);
                toast.success("Message Sent Successfully! We will get back to you soon.");
                e.target.reset();
                setIsModalOpen(false);
                console.log("message sent");
            }, (error) => {
                toast.error("Message Sending Failed! Please try again.")
                console.log(error.text);
            });
    };
    return (
        <footer class="new_footer_area bg_color">
            <div class="new_footer_top">
                <div class="container">
                    <div class="row">
                        <div className='linkWithCard'>
                            <div className='container '>
                                <div className='container '>
                                    <div className='container '>
                                        <div class="col col-md-12 row text-center d-flex justify-content-center pt-5">
                                            <div class="col-md-2">
                                                <h6 class="text-uppercase font-weight-bold">
                                                    <a onClick={() => window.open("https://training-in-chennai.co.in/about-us/")} class="text-black text-decoration-none line">About us</a>
                                                </h6>
                                            </div>
                                            <div class="col-md-2">
                                                <h6 class="text-uppercase font-weight-bold">
                                                    <a onClick={() => window.open("https://training-in-chennai.co.in/DLK%20CD%20Brochure%20Flipbook/DLK%20CD%20Brochure%20Flipbook/DK%20CD%20Brochure.html")} class="text-black text-decoration-none line">Profile</a>
                                                </h6>
                                            </div>
                                            <div class="col-md-2">
                                                <h6 class="text-uppercase font-weight-bold">
                                                    <a onClick={() => window.open("https://training-in-chennai.co.in/courses/")} class="text-black text-decoration-none line">Courses</a>
                                                </h6>
                                            </div>
                                            <div class="col-md-2">
                                                <h6 class="text-uppercase font-weight-bold ">
                                                    <a href="https://training-in-chennai.co.in/DLK%20CD%20Portfolio/DLK%20CD%20Portfolio/mobile/index.html" class="text-black text-decoration-none line">Portfolio</a>
                                                </h6>
                                            </div>

                                            <div class="col-md-2">
                                                <h6 class="text-uppercase font-weight-bold">
                                                    <a href="https://realtimeproject.in/blog/" class="text-black text-decoration-none line">Blog</a>
                                                </h6>
                                            </div>
                                            <div class="col-md-2">
                                                <h6 class="text-uppercase font-weight-bold">
                                                    <a class="text-black text-decoration-none line" onClick={showModal}>Contact Us</a>
                                                </h6>
                                            </div>
                                            <ModalPage
                                                onFinishFailed={onFinishFailed}
                                                onFinish={onFinish}
                                                handleCancel={handleCancel}
                                                showModal={showModal}
                                                isModalOpen={isModalOpen}
                                                setIsModalOpen={setIsModalOpen}
                                                sendEmail={sendEmail}
                                                form={form}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="my-4" />
                            <div class="row d-flex justify-content-center ">
                                <div class="col-lg-8 mb-5 p-md-0 p-4">
                                    <div className='col col-md-12 d-flex justify-content-center'>
                                        <text className='footerTitle'>Are You Located In Any Of These Areas?</text>
                                    </div>
                                    <p className='mt-4 footerPara'>
                                        Adyar, Adambakkam, Anna Salai, Ambattur, Ashok Nagar, Aminjikarai, Anna Nagar,
                                        Besant Nagar, Chromepet, Choolaimedu, Guindy, Egmore, K.K. Nagar, Kodambakkam,
                                        Ekkattuthangal, Kilpauk, Medavakkam, Nandanam, Nungambakkam, Madipakkam, Teynampet,
                                        Nanganallur, Mylapore, Pallavaram, OMR, Porur, Pallikaranai, Saidapet, St.Thomas Mount,
                                        Perungudi, T.Nagar, Sholinganallur, Triplicane, Thoraipakkam, Tambaram, Vadapalani,
                                        Villivakkam, Thiruvanmiyur, West Mambalam, Velachery and Virugambakkam.
                                    </p>
                                    <span className='mt-4 footerPara'>
                                        Our DLK CDC Vadapalani office is just few kilometre away from your location.
                                        If you need the best practical training in Chennai, driving couple of extra kilometres is worth it!.
                                    </span>
                                </div>
                                <div className='col col-md-12 col-12 watchNow'>
                                    <button type="button" class="started"
                                        onClick={showModal}
                                    >Enquiry Now</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="footer_bg">
                    <div class="footer_bg_one"></div>
                    <div class="footer_bg_two"></div>
                </div>
            </div>
            <div class="footer_bottom">

            </div>
        </footer>
    )
}

export default FooterAnime